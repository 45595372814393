<div class="row match-height">
  <div class="col-xl-12 col-lg-12 col-12">
    <div class="card shopping-cart">
      <div class="card-header pb-2">
        <h4 class="card-title mb-1">Trips</h4>
      </div>
      <div class="card-content">
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table text-center m-0">
              <thead>
                <tr>
                  <th>Driver</th>
                  <th>Rider</th>
                  <th>Location</th>
                  <th>Destination</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of items">
                  <td>{{ item.driver_name }}</td>
                  <td>{{ item.rider_name }}</td>
                  <td>{{ item.location }}</td>
                  <td>{{ item.destination }}</td>
                  <td>GHC {{ item.price }}</td>
                  <td>{{ item.date }}</td>

                  <td>
                    <button class="btn btn-primary" (click)="open(content)">
                      Details
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Modal title</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>One fine body&hellip;</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button>
  </div>
</ng-template>
