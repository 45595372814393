<nav
  class="navbar navbar-expand-lg navbar-light header-navbar {{
    transparentBGClass
  }}"
  [ngClass]="{
    'navbar-brand-center': menuPosition === 'Top',
    'navbar-static':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Static',
    'navbar-sticky':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Fixed',
    'fixed-top': isSmallScreen && menuPosition === 'Top'
  }"
>
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div
        class="
          navbar-toggle
          menu-toggle
          d-xl-none d-block
          float-left
          align-items-center
          justify-content-center
        "
        data-toggle="collapse"
        (click)="toggleSidebar()"
      >
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a
            class="nav-link apptogglefullscreen"
            id="navbar-fullscreen"
            href="javascript:;"
            appToggleFullscreen
            (click)="ToggleClass()"
            ><i class=" {{ toggleClass }} font-medium-3"></i
          ></a>
        </li>
        <li class="nav-item nav-search">
          <a
            class="nav-link nav-link-search"
            id="navbar-search"
            href="javascript:"
            (click)="toggleSearchOpenClass(true)"
            ><i class="ft-search font-medium-3"></i
          ></a>
          <div class="search-input {{ searchOpenClass }}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input
              [formControl]="control"
              #search
              class="input"
              type="text"
              placeholder="Explore Ekodot..."
              tabindex="0"
              autofocus
              data-search="template-search"
              [appAutocomplete]="autocomplete"
              (keyup.enter)="onEnter()"
              (keydown.esc)="onEscEvent()"
              (keyup)="onSearchKey($event)"
            />
            <div
              class="search-input-close"
              (click)="toggleSearchOpenClass(false)"
            >
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container
                  *ngIf="listItems | filter: control.value as result"
                >
                  <app-option
                    #searchResults
                    *ngFor="let option of result"
                    [url]="option.url"
                    [value]="option.name"
                    (click)="redirectTo(option.url)"
                    (mouseenter)="removeActiveClass()"
                  >
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        w-100
                      "
                    >
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <i class="{{ option.icon }} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length"
                    >No results found.</app-option
                  >
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a
                  class="logo-text"
                  [routerLink]="['/']"
                  *ngIf="config?.layout.menuPosition === 'Top'"
                >
                  <div class="logo-img">
                    <img class="logo-img" alt="Ekodot logo" [src]="logoUrl" />
                  </div>
                  <span class="text">EKODOT</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li
            class="dropdown nav-item mr-1"
            placement="bottom-left"
            display="static"
            ngbDropdown
          >
            <a
              class="
                nav-link
                dropdown-toggle
                user-dropdown
                d-flex
                align-items-end
              "
              id="dropdownBasic2"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
            >
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">Admin</span
                ><span class="text-right text-muted font-small-3"
                  >Available</span
                >
              </div>
              <img
                class="avatar"
                src="assets/img/icons/sketch-mac-icon.png"
                alt="avatar"
                height="35"
                width="35"
              />
            </a>
            <div
              class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
              aria-labelledby="dropdownBasic2"
              ngbDropdownMenu
            >
              <!-- <a class="dropdown-item" routerLink="/chat">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a> 
              <a class="dropdown-item" routerLink="/pages/profile">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My Inbox</span>
                </div>
              </a> -->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/pages/login">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a
              class="nav-link notification-sidebar-toggle"
              (click)="toggleNotificationSidebar()"
              ><i class="ft-align-right font-medium-3"></i
            ></a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</nav>
