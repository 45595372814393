import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";

//import { map, first } from "rxjs/operators";
import { map, filter, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";

@Injectable()
export class ProfileService {
  public driverProfile: any;
  public userProfile: any;
  public activityProfile: any;
  public dashboardProfile: any;
  public ActiveDriverProfile: any;
  public ItemProfile: any;
  public userFeed: any;
  public driverFeed: any;
  public completedTrips: any;
  // public userProfileRef: firebase.database.Reference;

  public subitemProfile: any;
  constructor(public af: AngularFireDatabase) {
    this.dashboardProfile = this.af.list(`DashboardSettings`);
    this.driverProfile = this.af.list(`driverProfile`);
    this.userFeed = this.af.list(`DashboardSettings/user/complains`);
    this.driverFeed = this.af.list(`DashboardSettings/driver/complains`);
    this.userProfile = this.af.list(`userProfile`);
    this.activityProfile = this.af.list(`Customer`);
    this.ActiveDriverProfile = this.af.list(`Drivers/AllDrivers`);
    this.ItemProfile = this.af.list(`ItemProfile`);
    this.completedTrips = this.af.list(`Completed`);

    this.subitemProfile = this.af.list(`ItemProfile/Stuffs`);
  }

  getActivityProfile() {
    return this.activityProfile;
  }

  getItemProfile(id: any) {
    return this.ItemProfile.child(id);
  }

  getDriverProfile(id: any) {
    return this.driverProfile.child(id);
  }

  _getDriverProfile() {
    return this.driverProfile;

    // let course = this.af.object("driverProfile");
    // return course;
    //course.subscribe((val) => console.log(val));
  }

  _getFeedUserProfile() {
    return this.userFeed;
  }

  getUserProfile(id: any) {
    return this.userProfile.child(id);
  }

  _getUserProfile() {
    return this.userProfile;
  }

  getFeedUserProfile(id: any) {
    return this.userFeed.child(id);
  }

  getFeedDriverProfile(id: any) {
    return this.driverFeed.child(id);
  }

  _getFeedDriverProfile() {
    return this.driverFeed;
  }

  ChangeItem(id: any, idi: any) {
    return this.af.list(`ItemProfile/${id}/Stuffs/${idi}`);
  }

  ChangeMainItem(id: any) {
    return this.af.list(`ItemProfile/${id}/`);
  }

  getInnerItem(id: any) {
    return this.af.list(`ItemProfile/${id}/Stuffs`);
  }

  getSecondaryItem(id: any, subid: any) {
    return this.af.list(`ItemProfile/${id}/Stuffs/${subid}`);
  }

  getDashboardProfile() {
    return this.dashboardProfile;
  }

  getActiveDriverProfile() {
    return this.ActiveDriverProfile;
  }

  updateState(id) {
    console.log("DRIVER ID::", id);
    return this.driverProfile.child(id).child("userInfo").update({
      active_state: true,
    });
  }

  disableDriver(id) {
    console.log("DRIVER ID::", id);
    return this.driverProfile.child(id).child("userInfo").update({
      active_state: false,
    });
  }

  getEventList() {
    return this.completedTrips;
    //return this.driverProfile.child("userInfo").child("/eventList");
  }
}
